<template>
  <div class="close-btn" @click="$emit('click')"></div>
</template>

<script>
export default {
  name: 'closeButton',
};
</script>

<style lang="scss" scoped>
.close-btn {
  cursor: pointer;
  color: #777;
  font-size: 48px;
  padding: 25px;
  opacity: 0.8;
  transition: all 0.2s ease;
  &:after {
    display: inline-block;
    content: '\00d7';
  }
  &:hover {
    opacity: 1;
  }
  &.dark {
    color: $text-color;
  }
}
</style>
